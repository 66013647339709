
import {
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
} from "vue";
import useFilters from "@/modules/common/useFilters";
import { getSaveCardiFrame, saveCard } from "@/api/cards.api";
import { useStore } from "vuex";

export interface Card {
  id: string;
  cardHolderName: string;
  cardType: string;
  expiration: string;
  createdDate: string;
  deletedDate: string;
  amount?: number;
  lastFour: any;
}

export interface Data {
  url?: string;
  isInProgress: boolean;
  isFormValid: boolean;
}

export default defineComponent({
  name: "CardsComponent",
  props: ["patientId"],
  setup(props, ctx) {
    const store = useStore();
    const { orderList } = useFilters();

    let data = reactive<Data>({
      url: undefined,
      isInProgress: false,
      isFormValid: false,
    });

    onMounted(async () => {
      window.addEventListener("message", messageListener);
      await getCardIframe();
    });

    onUnmounted(async () => {
      window.removeEventListener("message", messageListener);
    });

    async function messageListener(event) {
      const eventData = event.data?.data;
      const eventType = event.data?.event;
      if (event.origin === process.env.VUE_APP_NEXIO_URL) {
        if (eventType === "formValidations") {
          data.isFormValid = eventData.isFormValid;
        }
        if (eventType === "cardSaved") {
          let savedCard = {
            CardToken: eventData.token.token,
            LastFour: eventData.token.lastFour,
            CardType: eventData.token.cardType,
            CardHolderName: eventData.card.cardHolderName,
            Expiration:
              eventData.card.expirationMonth +
              "/" +
              eventData.card.expirationYear,
          };
          try {
            await saveCard({
              clientId: props.patientId,
              card: savedCard,
            });
          } finally {
            data.url = undefined;
            data.isInProgress = false;
            store.commit("STOPLOADING");
            ctx.emit("cardSaved");
          }
        }
      }
    }

    async function saveCardIframe() {
      store.commit("STARTLOADING");
      data.isInProgress = true;
      await nextTick();
      const saveIframe = window.document.getElementById("saveCardIFrame");
      if (saveIframe) {
        (saveIframe as any).contentWindow.postMessage(
          "posted",
          process.env.VUE_APP_NEXIO_URL
        );
      }
      return false; // keeps the form from auto submitting
    }

    async function getCardIframe() {
      await nextTick();
      const url = await getSaveCardiFrame({
        clientId: props.patientId,
      });
      data.url = url;
    }

    return {
      orderList,
      saveCardIframe,
      getCardIframe,
      data,
    };
  },
});
