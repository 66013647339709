
import { defineComponent, nextTick, onMounted, reactive, watch } from "vue";
import { uuid } from "vue-uuid";
import DecimalInput from "@/components/ABilling/DecimalInput.vue";
import useFilters from "@/modules/common/useFilters";
import { deleteCard, getCards, runSavedCard } from "@/api/cards.api";
import { useStore } from "vuex";
import { nexioTransactionMaxValue } from "@/utility";

export interface Card {
  id: string;
  cardHolderName: string;
  cardType: string;
  expiration: string;
  createdDate: string;
  deletedDate: string;
  amount?: number;
  lastFour: any;
}

export interface Data {
  items: Card[];
  url?: string;
  isInProgress: boolean;
  isFormValid: boolean;
  cardForTransaction?: Card;
  cardTransactionInProgress: boolean;
}

export default defineComponent({
  name: "CardsListComponent",
  props: ["patientId"],
  components: { DecimalInput },
  setup(props, ctx) {
    const store = useStore();
    const { orderList } = useFilters();

    let data = reactive<Data>({
      items: [],
      url: undefined,
      isInProgress: false,
      isFormValid: false,
      cardTransactionInProgress: false,
    });

    watch(
      () => props.patientId,
      async () => {
        data.items = [];
        await nextTick();
        await getAll();
      }
    );

    onMounted(async () => {
      await getAll();
    });

    async function getAll() {
      data.items = await getCards({
        clientId: props.patientId,
      });
    }

    async function deleteItem(card) {
      await deleteCard(card.id, props.patientId);
      data.items = await getCards({
        clientId: props.patientId,
      });
    }

    async function runCard() {
      if (data.cardForTransaction) {
        store.commit("STARTLOADING");
        data.cardTransactionInProgress = true;
        try {
          await runSavedCard({
            clientId: props.patientId,
            card: data.cardForTransaction,
            cardId: data.cardForTransaction.id,
            amount: data.cardForTransaction.amount,
            uniqueId: uuid.v4(),
          });
        } finally {
          data.cardTransactionInProgress = false;
          data.cardForTransaction.amount = undefined;
          data.cardForTransaction = undefined;
          store.commit("STOPLOADING");
          ctx.emit("transactionCompleted");
        }
      }
    }

    function selectCard(card) {
      data.cardForTransaction = card;
    }

    return {
      deleteItem,
      runCard,
      orderList,
      nexioTransactionMaxValue,
      selectCard,
      getAll,
      data,
    };
  },
});
