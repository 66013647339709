
import { defineComponent, nextTick, onMounted, reactive, watch } from "vue";
import useFilters from "@/modules/common/useFilters";
import { getTransactions } from "@/api/transactions.api";
import Multiselect from "@vueform/multiselect";
import DecimalInput from "@/components/ABilling/DecimalInput.vue";
import debounce from "lodash.debounce";
import PaginationUi from "@/components/ABilling/Pagination.vue";
import { Pagination } from "@/utility";
import { useStore } from "vuex";
import DateFloatComponent from "@/components/ABilling/DateFloatComponent.vue";

export interface Transaction {
  id: string;
  paymentId: string;
  transactionId: string;
  amount: string;
  attempts: string;
  transactionStatus: string;
  transactionType: string;
  transactionDate: any;
  createdDate: any;
}

export interface Status {
  value: string;
  name: string;
  description: string;
  code: string;
}

export interface Data {
  items: Transaction[];
  statuses: Status[];
  keyword: string | null;
  pagination: Pagination;
  filter: {
    startDate: any;
    endDate: any;
    minAmount: number | null;
    maxAmount: number | null;
    status: any;
  };
}

export default defineComponent({
  name: "TransactionsComponent",
  props: ["patientId"],
  components: { Multiselect, DecimalInput, PaginationUi, DateFloatComponent },
  setup(props) {
    const store = useStore();
    const { orderList } = useFilters();

    let data = reactive<Data>({
      items: [],
      keyword: null,
      statuses: [],
      filter: {
        startDate: null,
        endDate: null,
        minAmount: null,
        maxAmount: null,
        status: null,
      },
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 50,
      },
    });

    onMounted(async () => {
      data.statuses = store.getters.allNexioStatuses;
      await getAll();
    });

    watch(
      () => props.patientId,
      async () => {
        data.items = [];
        await nextTick();
        await search();
      }
    );

    async function debounceSearch() {
      await debounce(search, useFilters().debounceMs)();
    }

    async function getAll() {
      const req = {
        clientId: props.patientId,
        startDate: data.filter.startDate,
        endDate: data.filter.endDate,
        minAmount: data.filter.minAmount,
        maxAmount: data.filter.maxAmount,
        status: data.filter.status,
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        globalSearch: data.keyword,
      };

      const res = await getTransactions(req);

      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;
    }

    async function search() {
      await nextTick();
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;

      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function cleanFilter() {
      data.filter = {
        startDate: null,
        endDate: null,
        status: null,
        minAmount: null,
        maxAmount: null,
      };

      data.keyword = "";
      await getAll();
    }

    return {
      search,
      cleanFilter,
      pageChanged,
      pageSizeChanged,
      debounceSearch,
      orderList,
      getAll,
      data,
    };
  },
});
