
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  watch,
  nextTick,
} from "vue";
import { getOrganization } from "@/core/services/JwtService";
import CardListComponent from "@/modules/nexio/CardsList.vue";
import TransactionComponent from "@/modules/nexio/Transactions.vue";
import AddTransactionComponent from "@/modules/nexio/AddTransaction.vue";
import AddCard from "@/modules/nexio/AddCard.vue";
import Multiselect from "@vueform/multiselect";
import { searchByName } from "@/api/patient.api";
import { useStore } from "vuex";
import { getCards } from "@/api/cards.api";
import { getAllTransactions } from "@/api/transactions.api";

export interface Data {
  isLoading: boolean;
  selected?: number;
  patientOptions: { id: string }[];
  patientId?: string;
  isNewCardEnabled: boolean;
  mode: string;
}
export default defineComponent({
  name: "CreditCardPayment",
  props: ["patientId"],
  components: {
    AddCard,
    CardListComponent,
    TransactionComponent,
    AddTransactionComponent,
    Multiselect,
  },
  setup(props) {
    const store = useStore();

    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      isLoading: true,
      isNewCardEnabled: false,
      selected: undefined,
      patientOptions: [],
      mode: "list",
    });

    const cards = ref(null);
    const transactions = ref(null);

    onMounted(async () => {
      organizationId.value = getOrganization();
    });

    watch(
      () => data.patientId,
      async () => {
        data.isNewCardEnabled = false;
        await nextTick();
        await getCardsForPatient();
      }
    );

    async function getCardsForPatient() {
      data.isNewCardEnabled = false;
      await nextTick();
      if (!data.patientId) return;
      const cards = await getCards({
        clientId: data.patientId,
      });
      if (cards && cards.length < 3) {
        data.isNewCardEnabled = true;
      }
    }

    function transactionCompleted() {
      if (transactions?.value) (transactions?.value as any).getAll();
      setMode("list");
    }

    async function searchPatients(text) {
      const patients = await searchByName({ search: text });
      data.patientOptions = patients;
      return patients;
    }

    async function setMode(mode) {
      data.mode = mode;
      await getCardsForPatient();
    }

    async function cardSaved() {
      await setMode("cards");
    }

    return {
      cards,
      cardSaved,
      data,
      transactions,
      props,
      organizationId,
      setMode,
      searchPatients,
      store,
      transactionCompleted,
    };
  },
});
